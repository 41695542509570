<template>
    <div id="page-content">
        <div class="container py-2 bg-light covid">
            <div class="container">
                <div class="row">
                    <div class="col covid__heading d-flex">
                        <h3 class="ml-auto mr-auto mb-4 text-center">Respect des gestes barrières</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-3">
                        <figure class="text-center">
                            <img class="img-fluid covid__image" src="@/assets/img/png/covid/masque.png"
                                alt="Port du masque obligatoire">
                            <figcaption class="text-center">Port du masque obligatoire</figcaption>
                        </figure>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <figure class="text-center">
                            <img class="img-fluid covid__image" src="@/assets/img/png/covid/lavermains.png"
                                alt="Se laver les mains avant d'entrer dans le cabinet">
                            <figcaption class="text-center">Lavage des mains avant la séance</figcaption>
                        </figure>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <figure class="text-center">
                            <img class="img-fluid covid__image" src="@/assets/img/png/covid/coude.png"
                                alt="Tousser et éternuer dans son coude">
                            <figcaption class="text-center">Tousser et éternuer dans son coude</figcaption>
                        </figure>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <figure class="text-center">
                            <img class="img-fluid covid__image" src="@/assets/img/png/covid/mains.png"
                                alt="Ne pas se serrer la main">
                            <figcaption class="text-center">Ne pas se serrer la main</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col d-flex covid__heading">
                        <h3 class="mr-auto ml-auto mb-3 text-center">Dispositions particulières</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class=" m-1 m-lg-2 p-2 p-lg-4 rounded covid__content">
                            <h3>Accueil CoVID</h3>
                            <p>
                                Vous devez porter un masque en permanence dès qu'une autre personne est présente
                                et que vous entrez dans la bâtisse.<br>
                                La sortie se fait par une autre porte indiquée par
                                l’orthoptiste, ce qui vous évite de croiser d’autres patients.<br>
                                L'orthoptiste vient vous chercher à l'heure du rendez-vous.<br>
                                Du gel hydroalcoolique vous sera mis dans les mains par l'orthoptiste, et à chaque
                                contact à
                                risque.<br>Pour le paiement par chèque, merci de vous munir de votre stylo.

                            </p>
                            <h3>Protocole de désinfection</h3>
                            <p>
                                Entre chaque patient, un temps de désinfection est prévu sur toutes les surfaces en
                                contact
                                ainsi que sur le matériel utilisé, par un virucide ayant la norme EN 14476.<br>
                                Sur ce temps, il y a aussi une aération du lieu de consultation.<br>
                                L'orthoptiste se lave les mains ou se met du gel hydroalcoolique entre chaque patient et
                                dès
                                qu'il y a un contact à risque.<br>
                                Les WC sont condamnés, sauf urgence et suite à la demande auprès de la professionnelle
                                de
                                santé.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-4">
                <Carousel keyCarousel="covid"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import Carousel from '../components/Carousel'

    export default {
        name: 'Covid',
        methods:{
            ...mapActions(['updateActive'])
        },
        mounted() {
            this.updateActive(this.$route);
            document.title = 'Camille Barès - COVID 19';
            window.scrollTo(0, 0);
        },
        components:{
            Carousel
        }
    }

</script>

<style lang="scss" scoped>

</style>